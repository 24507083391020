import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Bingobot from "./pages/Bingobot";
import Nftbingoverse from "./pages/Nftbingoverse";
import Mynfts from "./pages/Mynfts";
import ScrollToTop from "./components/ScrollToTop";
import scrollreveal from "scrollreveal";
import "./sass/index.scss";
import Discordleaderboard from "./pages/Discordleaderboard";

function App() {
  const [theme, setTheme] = useState("dark");
  const changeTheme = () => {
    theme === "dark" ? setTheme("light") : setTheme("dark");
  };
  useEffect(() => {
    const registerAnimations = () => {
      const sr = scrollreveal({
        origin: "bottom",
        distance: "80px",
        duration: 2000,
        reset: false,
      });
      sr.reveal(
        `
        nav,
        .home,
        .collection,
        .team,
        .faqs,
        .blog,
        .joinus,
        footer
    `,
        {
          interval: 500,
        }
      );
    };
    registerAnimations();
  }, []);
  return (
    <div data-theme={theme} className="app-container">
      <ScrollToTop />
      <Navbar changeTheme={changeTheme} currentTheme={theme} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/bingo-bot" element={<Bingobot />} />
          <Route path="/bingo-verse" element={<Nftbingoverse />} />
          <Route path="/leaderboard" element={<Discordleaderboard />} />
          <Route path="/mynfts" element={<Mynfts />} />
        </Routes>
      <Footer />
    </div>
  );
}

export default App;
