import React, { useState } from "react";
import Loader from "../components/Loader";

export default function Discordleaderboard() {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };
  return (
    <div className="discordleaderboard">
      <div className="bingobot">
        <h1 class="title">B.B.B. Bingobot</h1>
        <div className="container">
          <div className="image-container">
            <div className="image">
              {loading && <Loader/>}
              <iframe
                src="https://unbelievaboat.com/leaderboard/952620950082433054/widget"
                onLoad={handleLoad}
                style={{ display: loading ? "none" : "block" }}
              ></iframe>
            </div>
            <div className="ellipse-container">
              <div className="ellipse pink"></div>
              <div className="ellipse yellow"></div>
            </div>
          </div>
          <div className="image-container">
            <div className="image">
              {loading && <Loader/>}
              <widgetbot
                server="952620950082433054"
                channel="1071793298752151553"
                onLoad={handleLoad}
                style={{ display: loading ? "none" : "block" }}
              ></widgetbot>
            </div>
            <div className="ellipse-container">
              <div className="ellipse green"></div>
              <div className="ellipse purple"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
