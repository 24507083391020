import React from "react";
import coin from "../assets/img/new_images/coin.png";
import { Link } from "react-router-dom";

export default function Nftbingoverse() {
  return (
    <div className="nftbingoverse">
      <div className="bingobot">
        <div className="container">
          <div className="image-container">
            <div className="image">
              <img src={coin} alt="bingobot" />
            </div>
            <div className="ellipse-container">
              <div className="ellipse pink"></div>
              <div className="ellipse purple"></div>
            </div>
          </div>
          <div className="content">
            <h1 className="title">Bingo BUCKS</h1>
            <p className="description">
              Welcome to the wonderful world of BingoVerse, where fun and
              fortune collide! Introducing BINGO BUCKS, our dynamic,
              non-monetized token that fuels the vibrant BingoVerse ecosystem. 🌌🎉
            </p>
            <p className="description">
              As you accumulate BINGO BUCKS through engaging gameplay and
              community interaction, you'll unlock exciting opportunities to
              earn monetized tokens, access exclusive events, and enjoy various
              benefits within the BingoVerse. 💰🔓
            </p>
            <p className="description">
              BINGO BUCKS is more than just a token; it's the lifeblood of our
              ever-expanding universe, enabling you to connect, thrive, and
              experience the thrill of bingo like never before. 🌟
            </p>
            <p className="description">
              Ready to dive into the endless excitement of the BingoVerse? Join
              the Blockchain Bingo Balls Discord community, where you'll find
              all the information you need to start your adventure with BINGO
              BUCKS and beyond! 🚀🎱💬
            </p>
            <div className="links">
              <Link to="https://discord.com/invite/blockchainbingoballs"
                target="_blank"
                className="link blue"
              >
                Join Discord
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
