import React, { useState, useEffect } from "react";
import Card from "./Card";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import legendary1 from "../assets/img/legendaries/1.png";
import legendary2 from "../assets/img/legendaries/2.png";
import legendary3 from "../assets/img/legendaries/3.png";
import legendary4 from "../assets/img/legendaries/4.png";
import legendary5 from "../assets/img/legendaries/5.png";
import legendary6 from "../assets/img/legendaries/6.png";
import legendary7 from "../assets/img/legendaries/7.png";
import legendary8 from "../assets/img/legendaries/8.png";
import legendary9 from "../assets/img/legendaries/9.png";
import legendary10 from "../assets/img/legendaries/10.png";
import legendary11 from "../assets/img/legendaries/11.png";
import legendary12 from "../assets/img/legendaries/12.png";
import legendary13 from "../assets/img/legendaries/13.png";
import legendary14 from "../assets/img/legendaries/14.png";
import legendary15 from "../assets/img/legendaries/15.png";
import legendary16 from "../assets/img/legendaries/16.png";
import legendary17 from "../assets/img/legendaries/17.png";
import { Link } from "react-router-dom";
import { walletConnect, walletDisconnect } from "../redux/wallet/walletActions";
import { fetchData } from "../redux/data/dataActions";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { FaPlus, FaMinus } from "react-icons/fa";
import { RxExternalLink } from "react-icons/rx";

export default function Collection() {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);

  const getData = () => {
    if (wallet.account !== "") {
      dispatch(fetchData(wallet.account));
    }
  };

  const blockchain = useSelector((state) => state.blockchain);
  let [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [nftMinted, setNftMinted] = useState(false);
  const [mintError, setMintError] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintfeedback, setMintfeedback] = useState("");
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    GAS_LIMIT: 0,
  });
  const BN = require("bn.js");

const claimNFTs = async () => {
  try {
    let cost = new BN(blockchain.cost.toString());
    console.log(cost.toString() + " cost");

    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = new BN(cost).mul(new BN(mintAmount)); 
    let totalGasLimit = new BN(300000).mul(new BN(mintAmount)); 

    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    const receipt = await blockchain.smartContract.methods
      .purchaseWithETH(wallet.account) 
      .send({
        gasLimit: new BN(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: wallet.account,
        value: totalCostWei,
      });

    setFeedback(`WOW, the ${CONFIG.NFT_NAME} is yours!`);
  } catch (err) {
    console.error(err);
    setFeedback("Sorry, something went wrong. Please try again later.");
  } finally {
    setClaimingNft(false);
  }
};

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getBlockchain = () => {
    if (wallet.account != null) {
      dispatch(connect(wallet));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getBlockchain();
  }, [wallet.account]);
  return (
    <div className="collection">
      <div className="container">
        <div className="legendaries">
          <h2 className="title">Legendaries</h2>
          <div className="background">
            <div className="ellipse red"></div>
            <div className="ellipse yellow"></div>
          </div>
          <div className="content">
            <div className="slider">
              <div className="slide-track">
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/151">
                    <Card image={legendary1} />
                  </Link>
                </div>
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/598">
                    <Card image={legendary2} />
                  </Link>
                </div>
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/718">
                    <Card image={legendary3} />
                  </Link>
                </div>
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/913">
                    <Card image={legendary4} />
                  </Link>
                </div>
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/420">
                    <Card image={legendary5} />
                  </Link>
                </div>
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/393">
                    <Card image={legendary6} />
                  </Link>
                </div>
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/559">
                    <Card image={legendary7} />
                  </Link>
                </div>
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/364">
                    <Card image={legendary8} />
                  </Link>
                </div>
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/365">
                    <Card image={legendary9} />
                  </Link>
                </div>
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/363">
                    <Card image={legendary10} />
                  </Link>
                </div>
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/664">
                    <Card image={legendary11} />
                  </Link>
                </div>
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/960">
                    <Card image={legendary12} />
                  </Link>
                </div>
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/812">
                    <Card image={legendary13} />
                  </Link>
                </div>
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/945">
                    <Card image={legendary14} />
                  </Link>
                </div>
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/876">
                    <Card image={legendary15} />
                  </Link>
                </div>
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/497">
                    <Card image={legendary16} />
                  </Link>
                </div>
                <div className="slide">
                  <Link to="https://app.ebisusbay.com/collection/blockchain-bingo-balls-main-hall/796">
                    <Card image={legendary17} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mint">
          <h2 className="title">Mint your Bingo Bus</h2>
          <p className="cost">Bingo Bus costs 450 CRO!</p>
          <div className="content">
            <div className="buttons">
              {wallet.account == null ? (
                <>
                  <button
                    className="main-button-yellow"
                    onClick={(e) => {
                      dispatch(walletConnect());
                      getData();
                    }}
                  >
                    Wallet Connect
                  </button>
                  {wallet.errorMsg !== "" ? <div>{wallet.errorMsg}</div> : null}
                </>
              ) : (
                <>
                  <button
                    className="main-button-yellow"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(walletDisconnect());
                    }}
                  >
                    Disconnect
                  </button>
                  <div>
                    <div>
                      {wallet.account != null ? (
                        <>
                          <p className="connected-account">
                            Connected account:{" "}
                            <span className="account">{wallet.account}</span>
                          </p>
                          <div>
                            {blockchain.totalSupply == null ? (
                              <></>
                            ) : (
                              <>
                                <p className="totalsupply">
                                  {blockchain.totalSupply} /{" "}
                                  {blockchain.maxSupply}
                                </p>
                                <div className="mint-amount">
                                  <button
                                    className="border-button"
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      decrementMintAmount();
                                    }}
                                  >
                                    <FaMinus />
                                  </button>
                                  <p className="number">{mintAmount}</p>
                                  <button
                                    className="border-button"
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      incrementMintAmount();
                                    }}
                                  >
                                    <FaPlus />
                                  </button>

                                  <button
                                    className="main-button-yellow"
                                    disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      claimNFTs();
                                    }}
                                  >
                                    {claimingNft ? "BUSY" : "BUY"}
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="no-mint">No connection</div>
                      )}
                    </div>
                    {Number(blockchain.totalSupply) >= 620 ? (
                      <div className="warning">The sale has ended.</div>
                    ) : (
                      <>
                        {wallet.account === "" ? (
                          <div>
                            {mintError ? (
                              <div className="text">
                                Something went wrong. Please connect to the{" "}
                                {CONFIG.NETWORK.NAME} network again to get your
                                NFT
                              </div>
                            ) : (
                              <div className="text">
                                Please connect to the {CONFIG.NETWORK.NAME}{" "}
                                network to get your NFT
                              </div>
                            )}
                            {blockchain.errorMsg !== "" ? (
                              <>
                                <div className="error">
                                  {blockchain.errorMsg}
                                </div>
                              </>
                            ) : null}
                          </div>
                        ) : (
                          <>
                            <p className="warning">{feedback}</p>
                            <p className="no-mint">{mintfeedback}</p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            {nftMinted ? (
              <Link to="/mynfts" className="link">
                My NFTs <RxExternalLink />
              </Link>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
