import React, { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { ImSun } from "react-icons/im";
import { BsFillMoonFill } from "react-icons/bs";
import logo from "../assets/img/new_images/logo_new.png";
import { Link } from "react-router-dom";
import { walletConnect, walletDisconnect } from "../redux/wallet/walletActions";
import { fetchData } from "../redux/data/dataActions";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";

export default function Navbar({ changeTheme, currentTheme }) {
  const [navState, setNavState] = useState(false);
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);

  const getData = () => {
    if (wallet.account !== "") {
      dispatch(fetchData(wallet.account));
    }
  };

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    GAS_LIMIT: 0,
  });

  const getBlockchain = () => {
    if (wallet.account != null) {
      dispatch(connect(wallet));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getBlockchain();
  }, [wallet.account]);

  return (
    <nav>
      <div className="brand-container">
        <div className="brand">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="toggle-container">
          <div className="toggle">
            {navState ? (
              <MdClose onClick={() => setNavState(false)} />
            ) : (
              <GiHamburgerMenu onClick={() => setNavState(true)} />
            )}
          </div>
          <div className="mode" onClick={changeTheme}>
            {currentTheme === "dark" ? (
              <ImSun className="light" />
            ) : (
              <BsFillMoonFill className="dark" />
            )}
          </div>
        </div>
      </div>
      <div className={`links-container ${navState ? "nav-visible" : ""}`}>
        <ul className="links">
          <li>
            <Link to="/bingo-bot">Bingo Bot</Link>
          </li>
          <li>
            <Link to="https://blockchainbingobazaar.com/en-be">
              Bingo Bazaar
            </Link>
          </li>
          <li>
            <Link to="/bingo-verse">NFT BingoVerse</Link>
          </li>
          <li onClick={changeTheme} className="light">
            {currentTheme === "dark" ? (
              <ImSun className="light" />
            ) : (
              <BsFillMoonFill className="dark" />
            )}
          </li>
          <li>
            {wallet.account == null ? (
              <>
                <button
                  className="main-button-yellow"
                  onClick={(e) => {
                    dispatch(walletConnect());
                    getData();
                  }}
                >
                  Wallet Connect
                </button>
                {wallet.errorMsg !== "" ? <div>{wallet.errorMsg}</div> : null}
              </>
            ) : (
              <>
                <Link className="mynfts" to="/mynfts">My NFTs</Link>
                <button
                  className="main-button-yellow"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(walletDisconnect());
                  }}
                >
                  Disconnect
                </button>
              </>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
}
