import React from "react";
import { Link } from "react-router-dom";

export default function Blog() {
  return (
    <div className="blog">
      <div className="container">
      <h1 className="title">Join Us</h1>
        <div id="jsonContent"></div>
        <div className="blog-button">
          <Link to="https://medium.com/@BlockchainBingoBalls"
            target="_blank"
            role="button"
          >
            View all posts
          </Link>
        </div>
      </div>
    </div>
  );
}
