import React from "react";
import { BsArrowRight } from "react-icons/bs";
import branding from "../assets/img/new_images/branding3.png";
import pink_ball  from "../assets/img/extra-balls/faq.png";
import { Link } from "react-router-dom";

export default function Faqs() {
  return (
    <div className="faqs">
      <div className="faq green">
        <div className="content">
          <h2 className="title">What is Blockchain Bingo Balls (BBB)?</h2>
          <p className="description">
            🎉 Step into the exhilarating world of Blockchain Bingo Balls (BBB)
            - the ultimate fusion of classic bingo and cutting-edge blockchain
            technology! 🔗🎱
          </p>
          <p className="description">
            Experience the thrill of playing bingo like never before on Discord,
            powered by our exceptional and exclusive Discord Bingo Bot. 🤖🔥
            Dive into our dazzling NFT collections, showcasing rare,
            one-of-a-kind digital art pieces that capture the essence of this
            revolutionary gaming experience. 🎨🌟
          </p>
          <p className="description">
            Don't forget to join our vibrant community of bingo enthusiasts and
            blockchain aficionados, and show your support by shopping at our
            Blockchain Bazaar - your one-stop shop for all things BBB! 💰🛍️
            Embark on this unforgettable journey with us and redefine the future
            of bingo, one ball at a time! 🚀🌐
          </p>
        </div>
        <div className="image">
          <img src={branding} alt="Branding 3" />
          <div className="ellipse pink"></div>
        </div>
      </div>
      <div className="faq purple">
        <div className="card-container">
          <img src={pink_ball} alt="Pink Ball" />
          <div className="ellipse green"></div>
        </div>
        <div className="content">
          <h2 className="title">What happens in the BBB Discord Server?</h2>
          <p className="description">
            🌟 Discover the exhilarating world of the BBB Discord Server! 🌟
          </p>
          <p className="description">
            Get ready for action-packed bingo games, brought to life with our
            innovative bot and the dynamic live-stream capabilities of Discord. 🎱🚀
          </p>
          <p className="description">
            Stay in the loop with everything BBB-related, as essential
            information is shared right within the server. Don't miss a beat -
            join now! 📣🔥
          </p>
          <p className="description">
            Claim unique, exclusive roles, some reserved only for the select few
            who meet specific requirements! 🏆🎖️ Prepare for the big reveal, as
            you'll learn about the jaw-dropping VIP roles that grant you access
            to elite channels. Here, you'll witness unparalleled bingo games,
            stakes, and prizes that are truly out of this world... or should we
            say, in the bingoverse! 🌌✨
          </p>
          <Link to="https://discord.com/invite/blockchainbingoballs" target="_blank" className="link">
            Join Us <BsArrowRight />
          </Link>
        </div>
      </div>
    </div>
  );
}
