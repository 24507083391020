import React from "react";
import { Modal } from "react-bootstrap";
import coin from "../assets/img/new_images/coin.png";
import { Link } from "react-router-dom";

function CustomModal(props) {
  const handleEnter = () => {
    props.onHide();
  };
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <div className="modal-content-inner">
        <Modal.Title>🌌🚀 Welcome to the BingoVerse! 🚀🌌</Modal.Title>
        <Modal.Body>
          ✅ Click to launch yourself into a world of bingo excitement like
          never before! Prepare your dabbers and keep your eyes peeled for the
          ultimate WIN! 🎉🏆
        </Modal.Body>
        <Modal.Footer>
          <Link to="/bingo-verse" className="coin">
            <div className="coin-animation"><img src={coin} alt="coin" /></div>
          </Link>
          <button onClick={handleEnter}>Enter</button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default CustomModal;
