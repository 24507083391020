import React from "react";
import { Link } from "react-router-dom";

export default function Bingobot() {
  return (
    <div className="bingo-bot">
      <div className="bingobot">
        <div className="container">
          <div className="content">
            <div className="bingo-bot-content">
            <h1 className="title">B.B.B. Bingobot</h1>
            <p className="description">
              Meet Bart, your laugh-tastic, number-crunching, Blockchain Bingo
              Balls bot! 🤖 Powered by algorithms just as punchy as his jokes,
              Bart's here to help you get your bingo on while keeping the laughs
              coming. 🎉
            </p>
            <p className="description">
              Notorious for his quick wit, this bingo bot is part comedian, part
              mathematician, and 100% the life of the party. Bart loves long
              walks on the blockchain, wowing players with his bingo ball
              banter, and cracking jokes that'll have you ROFLing in your seat!
              🤣
            </p>
            <p className="description">
              When he's not busy being the mastermind behind your bingo games,
              Bart enjoys calculating probabilities of winning, experimenting
              with RNG, and moonlighting as a stand-up comic in a parallel
              digital universe. 🌌
            </p>
            <p className="description">
              Ready to dab those numbers and LOL your way to victory? Join Bart
              and the Blockchain Bingo Balls crew on Discord - where the fun
              never stops, and the bingos just keep rolling in! 🎱💬🎯
            </p>
            <div className="links">
              <Link to="https://discord.com/invite/blockchainbingoballs"
                target="_blank"
                className="link blue"
              >
                Join Discord
              </Link>
            </div>
            </div>
          </div>
          <div className="image-container">
            <div className="image">
              <widgetbot
                server="952620950082433054"
                channel="1071793298752151553"
              ></widgetbot>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
