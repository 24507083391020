import React from "react";
import Card from "./Card";
import cyrpto_goku from "../assets/img/team/CyptoGoku.png";
import chycero from "../assets/img/team/chycero.jpg";
import dmk from "../assets/img/team/dmk.png";
import gundam from "../assets/img/team/gundam_ball.png";
import { FaTwitter, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Team() {
  return (
    <div className="team">
      <div className="title-container">
        <h1 className="title">Team</h1>
      </div>
      <div className="cards">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
            <div className="team-member">
              <div className="team-member-inner">
                <div className="team-member-front">
                  <Card image={cyrpto_goku} />
                </div>
                <div className="team-member-back">
                  <div className="title">CRYPTO GOKU</div>
                  <div className="subtitle black">FOUNDER</div>
                  <div className="description">
                    Introducing CryptoGoku, your passionate and determined BBB
                    guide! 🌟🔥
                  </div>
                  <div className="description">
                    With an unwavering focus on excellence, CryptoGoku has been
                    immersed in the crypto scene for over a year and ventured
                    into the NFT universe since October '21. Recognizing a
                    unique opportunity in the dynamic Cronos NFT landscape, he
                    breathed life into the ground-breaking Blockchain Bingo
                    Balls (BBB) project. 💡🚀
                  </div>
                  <div className="description">
                    CryptoGoku's vision extends beyond the success of the BBB
                    project team. He aims to uplift the entire Bingo community
                    by forging mutually beneficial partnerships across the
                    blockchains and real world generously giving back to all its
                    community.
                  </div>
                  <div className="description">
                    Together, let's create a brighter future for the BingoVerse!
                    🌐🤝
                  </div>
                  <div className="social-links">
                    <Link to="https://twitter.com/CryptoGoku9" target="_blank">
                      <FaTwitter />
                    </Link>
                    <Link to="" target="_blank">
                      <FaInstagram />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
          <div className="team-member">
              <div className="team-member-inner">
                <div className="team-member-front">
                  <Card image={gundam} />
                </div>
                <div className="team-member-back">
                  <div className="title">Ms Cryptoslayer</div>
                  <div className="subtitle black">FOUNDER - LEAD ARTIST</div>
                  <div className="description">
                    Introducing MSCryptoSlayer, the artistic powerhouse behind
                    Blockchain Bingo Balls! 🎨🔥
                  </div>
                  <div className="description">
                    As a passionate Manga enthusiast with an extraordinary
                    artistic flair, MSCryptoSlayer brings an unparalleled
                    creative touch to the BBB project. His versatile talent
                    spans across NFT creation, project branding, and much more,
                    turning ideas into visual masterpieces. 🌟✨
                  </div>
                  <div className="description">
                    Got an art request? Don't hesitate to reach out to
                    MSCryptoSlayer, your go-to artist for all your creative
                    needs in the world of Blockchain Bingo Balls and beyond!
                    🖌️🚀
                  </div>
                  <div className="social-links">
                    <Link to="https://twitter.com/ModernSingledad"
                      target="_blank"
                    >
                      <FaTwitter />
                    </Link>
                    <Link to="#" target="_blank">
                      <FaInstagram />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
          <div className="team-member">
              <div className="team-member-inner">
                <div className="team-member-front">
                  <Card image={chycero} />
                </div>
                <div className="team-member-back">
                  <div className="title">CHYCERO</div>
                  <div className="subtitle black">COMMUNITY LEAD</div>
                  <div className="description">
                    Introducing Chycero, Blockchain Bingo Balls' very own
                    resident Joker and Community Lead! 🃏🎉
                  </div>
                  <div className="description">
                    With a wicked sense of humor and an infectious laugh,
                    Chycero knows how to keep things lively and engaging in the
                    community. Don't let his playful antics fool you, though -
                    as the Community Lead, Chycero takes his role very
                    seriously. He expertly juggles his dual responsibilities,
                    ensuring a fun-filled and well-managed environment for all
                    members. 😄🌟
                  </div>
                  <div className="description">
                    But beware - you wouldn't want to find yourself on Chycero's
                    serious side, as he's not one to be trifled with when it
                    comes to maintaining harmony and order in the Blockchain
                    Bingo Balls community. Stay on his good side, and you'll be
                    guaranteed an unforgettable ride through the world of BBB!
                    😅💼
                  </div>
                  <div className="social-links">
                    <Link to="https://twitter.com/chycero_nft" target="_blank">
                      <FaTwitter />
                    </Link>
                    <Link to="https://www.instagram.com/pom.nigel/"
                      target="_blank"
                    >
                      <FaInstagram />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
          <div className="team-member">
            
              <div className="team-member-inner">
                <div className="team-member-front">
                  <Card image={dmk} />
                </div>
                <div className="team-member-back">
                  <div className="title">DMKrypto</div>
                  <div className="subtitle black">MARKETING MANAGER</div>
                  <div className="description">
                    DMKrypto is the Marketing Maverick of Blockchain Bingo
                    Balls.
                  </div>
                  <div className="description">
                    🦇 Dark, Mysterious, and Multitalented 🌑
                  </div>
                  <div className="description">
                    Greetings, fellow Bingo enthusiasts! I'm DMKrypto, your
                    resident Marketing Manager and so much more for Blockchain
                    Bingo Balls. Like the enigmatic Batman, I navigate the
                    shadows of the blockchain world, equipped with an arsenal of
                    gadgets and strategies to bring you the ultimate Bingo
                    experience.
                  </div>
                  <div className="social-links">
                    <Link to="https://twitter.com/DMKrypto" target="_blank">
                      <FaTwitter />
                    </Link>
                    <Link to="#" target="_blank">
                      <FaInstagram />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
