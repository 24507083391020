const initialState = {
  loading: false,
  smartContract: null,
  errorMsg: "",
  totalSupply: null,
  maxSupply: null,
  cost: null,
  walletOfOwner: null,
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        smartContract: action.payload.smartContract,
        errorMsg: action.payload.errorMsg,
        totalSupply: action.payload.totalSupply,
        cost: action.payload.cost,
        maxSupply: action.payload.maxSupply,
        walletOfOwner: action.payload.walletOfOwner,
      };
    default:
      return state;
  }
};

export default blockchainReducer;
