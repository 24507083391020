import React from "react";
import bingobot from "../assets/img/new_images/bingobot.png";
import { Link } from "react-router-dom";

export default function Bingobot() {
  return (
    <div className="bingobot">
      <div className="container">
        <div className="content">
          <h1 className="title">B.B.B. Bingobot</h1>
          <p className="description">
            Bart is a bingo bot that uses algorithms to generate funny and witty
            comments while helping players play bingo. He is part comedian and
            part mathematician and enjoys calculating probabilities of winning
            and experimenting with RNG. Bart can be found on Discord with the
            Blockchain Bingo Balls crew, where players can enjoy playing bingo
            while being entertained by Bart's jokes.
          </p>
          <div className="links">
            <Link to="https://discord.com/invite/blockchainbingoballs" target="_blank" className="link blue">
              Join Discord
            </Link>
            <Link to="/bingo-bot" className="link pink">
              Learn More
            </Link>
          </div>
        </div>
        <div className="image-container">
          <div className="image">
            <img src={bingobot} alt="bingobot" />
          </div>
          <div className="ellipse-container">
            <div className="ellipse pink"></div>
            <div className="ellipse purple"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
