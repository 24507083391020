import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";

export default function Mynfts() {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const blockchain = useSelector((state) => state.blockchain);
  const [isLoading, setIsLoading] = useState(false);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    GAS_LIMIT: 0,
  });

  const [nfts, setNFTs] = useState([]);
  const [feedback, setFeedback] = useState("");

  const getBlockchain = () => {
    if (wallet.account != null) {
      dispatch(connect(wallet));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getBlockchain();
  }, [wallet.account]);

  useEffect(() => {
    if (blockchain.smartContract) {
      setIsLoading(true);
      showNFTs();
    }
  }, [blockchain.smartContract]);

  const showNFTs = async () => {
    if (!wallet.account) {
      setFeedback("Please connect your wallet to view your NFTs.");
      setNFTs([]);
      setIsLoading(false);
      return;
    }

    const tokenIds = await blockchain.smartContract.methods
      .walletOfOwner(wallet.account)
      .call();

    const nfts = [];
    for (let i = 0; i < tokenIds.length; i++) {
      const tokenId = tokenIds[i];
      const nftMetadataUrl = await blockchain.smartContract.methods
        .tokenURI(tokenId)
        .call();
      const nftMetadataUrlParts = nftMetadataUrl.split("//");
      const ipfsHash = nftMetadataUrlParts[1];
      const ipfsUrl = `https://ipfs.io/ipfs/${ipfsHash}`;
      const response = await fetch(ipfsUrl);
      const nftMetadata = await response.json();
      const formattedImageURL = nftMetadata.image.replace(
        "ipfs://",
        "https://ipfs.io/ipfs/"
      );
      nfts.push({
        tokenId: tokenId,
        name: nftMetadata.name,
        image: formattedImageURL,
      });
    }

    setFeedback(`Here are your ${nfts.length} ${CONFIG.NFT_NAME} NFTs:`);
    setNFTs(nfts);
    setIsLoading(false);
  };

  return (
    <div className="mynfts">
      <div className="mynfts-content">
        <div className="container">
          <div className="content">
            <h1 className="title">My Nfts</h1>
            <div className="row">
              {isLoading ? (
                <p className="loading-spinner">Loading...</p>
              ) : (
                <>
                  {wallet.account != null ? (
                    <>
                      {nfts.map((nft) => (
                        <div
                          className="col-xl-4 col-lg-4 col-md-4"
                          key={nft.tokenId}
                        >
                          <div className="item">
                            <img src={nft.image} alt={`${nft.tokenId} image`} />
                            <h4>Token ID {nft.tokenId}</h4>
                            <p>{nft.name}</p>
                          </div>
                        </div>
                      ))}
                      {nfts.length === 0 && (
                        <div className="notifications">
                          <p>You don't have any Bingo Bus. YET! </p>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="notifications">
                      <p>Your wallet is not connected!</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
