import React, { useEffect, useState } from "react";
import HomeComponent from "../components/Home";
import Collection from "../components/Collection";
import Bingobot from "../components/Bingobot";
import Faqs from "../components/Faqs";
import Blog from "../components/Blog";
import Team from "../components/Team";
import Joinus from "../components/Joinus";
import CustomModal from "../components/CustomModal";

export default function Home() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(true);
  }, []);

  const handleClose = () => setShowModal(false);

  return (
    <>
      <HomeComponent />
      <Collection />
      <Bingobot />
      <Faqs />
      <Blog />
      <Team />
      <Joinus />
      <CustomModal show={showModal} onHide={handleClose} />
    </>
  );
}
