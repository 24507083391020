import React from "react";
import logo from "../assets/img/new_images/logo_new.png";
import coin from "../assets/img/new_images/coin.png";

import { FaMediumM, FaDiscord, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <footer>
      <div className="upper">
        <div className="brand-container">
          <div className="brand">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="links">
          <Link to="/bingo-verse" className="coin">
            <div className="coin-animation"><img src={coin} alt="coin" /></div>
          </Link>
          <Link to="https://medium.com/@BlockchainBingoBalls"
            target="_blank"
            className="discord"
          >
            <FaMediumM />
          </Link>
          <Link to="https://discord.com/invite/blockchainbingoballs"
            target="_blank"
            className="discord"
          >
            <FaDiscord />
          </Link>
          <Link to="https://twitter.com/BlockBingoBall" target="_blank">
            <FaTwitter />
          </Link>
        </div>
      </div>
      <div className="lower">
        <span>&copy; Blockchain Bingo Balls | All Right Reserved</span>
      </div>
    </footer>
  );
}
