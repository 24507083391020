import React from "react";
import discord from "../assets/img/extra-balls/discord.png";
export default function Joinus() {
  return (
    <div className="joinus">
      <div className="container">
        <div className="content">
          {/* <p className="sub-title">Launching Soon</p> */}
          <h1 className="title">Join Us</h1>
          <p className="description">
            Join our community to play various games!
          </p>
          <iframe className="discord-frame" src="https://discord.com/widget?id=952620950082433054&theme=dark" allowtransparency="true" frameBorder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
          <button>Leaderboard</button>
        </div>
        <div className="image-container">
          <div className="image">
            <img src={discord} alt="discord ball" />
          </div>
          <div className="ellipse-container">
            <div className="ellipse pink"></div>
            <div className="ellipse purple"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
