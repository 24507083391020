import React from "react";
export default function Home() {
  return (
    <div className="home">
      <div className="container">
        <div className="image-container">
          <div className="ellipse-container">
            <div className="ellipse pink"></div>
            <div className="ellipse orange"></div>
          </div>
        </div>
        <div className="image-container2">
          <div className="ellipse-container">
            <div className="ellipse green"></div>
            <div className="ellipse purple"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
